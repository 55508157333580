exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktuelles-tsx": () => import("./../../../src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-apparel-tsx": () => import("./../../../src/pages/apparel.tsx" /* webpackChunkName: "component---src-pages-apparel-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kooperationspartner-tsx": () => import("./../../../src/pages/kooperationspartner.tsx" /* webpackChunkName: "component---src-pages-kooperationspartner-tsx" */),
  "component---src-pages-leistungen-tsx": () => import("./../../../src/pages/leistungen.tsx" /* webpackChunkName: "component---src-pages-leistungen-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

